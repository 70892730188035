import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import { doc, getDoc, collection, addDoc, onSnapshot, updateDoc, serverTimestamp } from 'firebase/firestore';
import { auth } from '../../firebase/auth';
import { FiArrowLeft, FiLink, FiLogOut, FiCamera } from 'react-icons/fi';
import logo from '../../assets/images/logos/logo-partysync-official.png';
import logotext from '../../assets/images/logos/logo-partysync-text-v2.png';
import { logout } from '../../firebase/auth';

import birthdayBanner from '../../assets/images/eventBanners/birthday-banner.png';
import graduationBanner from '../../assets/images/eventBanners/graduation-banner.png';
import bacheloretteBanner from '../../assets/images/eventBanners/bachelorette-banner.png';
import thanksgivingBanner from '../../assets/images/eventBanners/thanksgiving-banner.png';
import defaultBanner from '../../assets/images/eventBanners/thanksgiving-banner.png';

// Import custom icons
import rsvpIcon from '../../assets/images/icons/RSVP_Tool_Icon_1.png';
import menuIcon from '../../assets/images/icons/Menu_Builder_Icon.png';
import previewIcon from '../../assets/images/icons/Guest_Preview_Icon.png';
import expenseIcon from '../../assets/images/icons/Expense_Tracker_Icon.png';
import chatIcon from '../../assets/images/icons/Party_Chat_Icon.png';

//import movieIcon from '../../assets/images/icons/movie-icon.png'; // Assuming custom icon for Movie Picker
//import chatIcon from '../../assets/images/icons/chat-icon.png';   // Assuming custom icon for Party Chat

function EventDetail() {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [rsvps, setRsvps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invitationLink, setInvitationLink] = useState('');
  const [countdown, setCountdown] = useState('');
  const [currentTip, setCurrentTip] = useState('');
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const navigate = useNavigate();
  const formatTime = (time) => {
    if (!time) return ''; // Return an empty string if time is undefined
  
    const [hour, minute] = time.split(':');
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? 'PM' : 'AM';
    const formattedHour = hourInt % 12 || 12; // Convert hour to 12-hour format, where 0 becomes 12
    return `${formattedHour}:${minute} ${ampm}`;
  };
  const tips = [
    "Check the guest list and make sure everyone you want is invited!",
    "Finalize the event theme and decorations.",
    "Confirm the menu and plan your shopping list.",
    "Check the weather forecast and prepare accordingly.",
    "Send a reminder message to your guests!",
    "Set up your playlist and test your audio/video equipment.",
    "Get some rest—your event is almost here!"
  ];

  {/* 
     // Testing File Upload
  const [uploadMessage, setUploadMessage] = useState("");
    */}

//
 const [bannerModalOpen, setBannerModalOpen] = useState(false); // To track modal visibility
 const [customBannerUrl, setCustomBannerUrl] = useState('');  // To store the custom banner URL
 const [previewBannerUrl, setPreviewBannerUrl] = useState(null);
 const [previewLoading, setPreviewLoading] = useState(false);

 //custom event banner

  const eventBackgroundBanners = {
    Birthday: birthdayBanner,
    Graduation: graduationBanner,
    Bachelorette: bacheloretteBanner,
    Thanksgiving: thanksgivingBanner,
  };

  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true); // Show loading state
  
      try {
        const eventDoc = await getDoc(doc(db, 'events', eventId)); // Fetch event from Firestore
        if (eventDoc.exists()) {
          const eventData = eventDoc.data();
          setEvent({ id: eventDoc.id, ...eventData });
  
          // Fetch and set the custom banner URL if available
          setCustomBannerUrl(eventData.bannerUrl || ''); // Fallback to an empty string
        } else {
          console.error(`Event with ID ${eventId} does not exist.`);
          setCustomBannerUrl(''); // Clear custom banner URL in case of missing event
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
  
      setLoading(false); // Hide loading state
    };
  
    fetchEvent();
  }, [eventId]); // Re-run only when eventId changes
  

  useEffect(() => {
    if (event) {
      const link = `${window.location.origin}/guest-event/${eventId}`;
      setInvitationLink(link);

      startCountdown(event.date);
      rotateTips();
    }
  }, [event, eventId]);

  const startCountdown = (eventDate) => {
    const eventTime = new Date(eventDate).getTime();
    const updateCountdown = () => {
      const currentTime = new Date().getTime();
      const timeDifference = eventTime - currentTime;

      if (timeDifference <= 0) {
        setCountdown("Event is happening now!");
      } else if (timeDifference > 24 * 60 * 60 * 1000) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        setCountdown(`${days} Days ${hours} Hours`);
      } else {
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        setCountdown(`${hours} Hours ${minutes} Minutes ${seconds} Seconds`);
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  };

  const rotateTips = () => {
    let index = 0;
    setCurrentTip(tips[index]);
    setInterval(() => {
      index = (index + 1) % tips.length;
      setCurrentTip(tips[index]);
    }, 20000);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(invitationLink).then(() => {
      alert('Invitation link copied to clipboard!');
    }).catch((error) => {
      console.error('Error copying link: ', error);
    });
  };

 // custom event banner
  const backgroundImage = customBannerUrl || eventBackgroundBanners[event?.type] || defaultBanner;
  const handleBannerChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    setPreviewLoading(true); // Start loading
  
    try {
      const response = await fetch("/api/upload", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fileName: file.name, fileType: file.type }),
      });
  
      if (!response.ok) throw new Error("Failed to get signed URL");
  
      const { url } = await response.json();
  
      const uploadResponse = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": file.type },
        body: file,
      });
  
      if (!uploadResponse.ok) throw new Error("Failed to upload file");
  
      const imageUrl = url.split("?")[0]; // Extract uploaded image URL
      setPreviewBannerUrl(imageUrl); // Set preview banner URL
    } catch (error) {
      console.error("Error uploading banner:", error);
      alert("Failed to upload banner. Please try again.");
    } finally {
      setPreviewLoading(false); // Stop loading
    }
  };
  
  
  
  const saveCustomBanner = async () => {
    if (!previewBannerUrl) {
      alert("Please upload an image first.");
      return;
    }
  
    try {
      const eventDocRef = doc(db, "events", eventId);
      await updateDoc(eventDocRef, { bannerUrl: previewBannerUrl });
      setCustomBannerUrl(previewBannerUrl); // Commit the preview to the main banner
      setPreviewBannerUrl(null); // Clear preview
      setBannerModalOpen(false); // Close modal
      alert("Banner updated successfully!");
    } catch (error) {
      console.error("Error saving banner:", error);
      alert("Failed to save banner. Please try again.");
    }
  };
  
  
  const revertToDefaultBanner = async () => {
    try {
      const eventDocRef = doc(db, "events", eventId);
      await updateDoc(eventDocRef, { bannerUrl: null });
      setCustomBannerUrl('');
      alert("Reverted to default banner!");
      setBannerModalOpen(false);
    } catch (error) {
      console.error("Error reverting banner:", error);
      alert("Failed to revert banner. Please try again.");
    }
  };

  const closeBannerModal = () => {
    setPreviewBannerUrl(null); // Reset preview
    setBannerModalOpen(false);
  };
  
//custom event banner ^  
  
  
  
  const openFeedbackModal = () => {
    setFeedbackModalOpen(true);
  };

  const closeFeedbackModal = () => {
    setFeedbackModalOpen(false);
    setFeedbackText('');
  };
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  const handleFeedbackSubmit = async () => {
    const userEmail = auth.currentUser?.email || "Anonymous";
    
    if (!auth.currentUser) {
      alert("You must be signed in to submit feedback.");
      return;
    }
  
    try {
      await addDoc(collection(db, 'Feedback'), {
        userEmail,
        eventId,           // The event ID being referenced
        feedbackText,      // The actual feedback content
        timestamp: serverTimestamp(), // Firebase timestamp
      });
      alert("Feedback submitted! Thank you.");
      closeFeedbackModal();
    } catch (error) {
      console.error("Error submitting feedback: ", error);
      alert("There was an issue submitting your feedback. Please try again.");
    }
  };
 
  {
/*
 //testing file upload
  const uploadFile = async (file) => {
    try {
      // Step 1: Request signed URL from the serverless function
      const response = await fetch("/api/upload", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fileName: file.name,
          fileType: file.type,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to get signed URL");
      }
  
      const { url } = await response.json();
      console.log("Generated signed URL:", url);
  
      // Step 2: Upload the file to S3 using the signed URL
      const uploadResponse = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": file.type }, // ensure this matches file.type
        body: file,
      });
  
      if (!uploadResponse.ok) {
        throw new Error("Failed to upload file to S3");
      }
  
      console.log("File uploaded successfully");
      setUploadMessage("File uploaded successfully!");
    } catch (error) {
      console.error("Error during file upload:", error);
      setUploadMessage("Error uploading file.");
    }
  };
  
  

  //testing file upload 
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadFile(file);
    }
  };
*/

  }
    
   
  

  if (loading) {
    return <div className="text-center p-6">Loading Event Details...</div>;
  }

  if (!event) {
    return <div className="text-center p-6">Event not found.</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
    {/* Standardized Header */}
    <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
      <img
        src={logotext}
        alt="PartySync Logo"
        className="h-8 cursor-pointer"
      />
      <button
        className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
        onClick={handleLogout}
      >
        LOG OUT
      </button>
    </header>

    <main className="p-6 min-h-screen bg-gradient-to-b from-offwhite to-offwhite mt-20">
      {/* Back Button Below Header */}
      <div className="flex justify-start items-center mb-4">
        <button
          className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
          onClick={() => navigate('/role-selection')}
        >
          <FiArrowLeft size={32} />
        </button>
      </div>

      <div className="max-w-7xl mx-auto">
        {/* Event Banner */}
        <div
  className="relative bg-white rounded-2xl mb-6"
  style={{
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}
>
  {/* Responsive container for the banner */}
  <div
    className="w-full h-40 sm:h-60 md:h-80 lg:h-[350px] xl:h-[400px]"
  ></div>

  {/* Edit Icon */}
  <button
    className="absolute top-4 right-4 bg-white bg-opacity-70 rounded-full p-2 hover:shadow-lg transition z-10"
    title="Edit Event Banner"
    onClick={() => setBannerModalOpen(true)}
  >
    <FiCamera size={32} className="text-fontGreen" />
  </button>
</div>

        <div className="bg-white p-4 md:p-6 rounded-2xl border-4 border-fontGreen mb-6 flex justify-between items-center">
  {/* Event Details */}
  <div className="flex flex-col">
    <span className="text-xl md:text-2xl font-bold text-fontGreen">
      {event.name}
    </span>
    <span className="text-lg md:text-xl text-fontGreen">
      Date: <span className="font-medium">{event.date}</span>
    </span>
    <span className="text-lg md:text-xl text-fontGreen">
      Time: <span className="font-medium">{formatTime(event.time)}</span>
    </span>
    <span className="text-lg md:text-xl text-fontGreen">
      Location: <span className="font-medium">{event.location}</span>
    </span>
  </div>

  {/* Share Invite Button */}
  <button
    className="flex items-center gap-2 bg-fontGreen font-bold text-white text-md md:text-lg py-2 px-4 rounded-lg md:rounded-xl hover:transition transform hover:scale-105"
    onClick={copyToClipboard}
    title="Copy Invitation Link"
  >
    <FiLink /> Share Invite
  </button>
</div>

  
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <EventSection title="RSVP TOOL" description="Manage and invite guests to your event" iconSrc={rsvpIcon} color="white" link={`/host-dashboard/event/${eventId}/rsvp`} />
            <EventSection title="MENU BUILDER" description="Create and assign menu items for your event" iconSrc={menuIcon} color="white" link={`/host-dashboard/event/${eventId}/menu`} />
            <EventSection title="PREVIEW INVITE" description="Control what features guests can see for this event" iconSrc={previewIcon} color="white" link={`/host-dashboard/event/${eventId}/guest-view-settings`} />
            <EventSection title="EXPENSE TRACKER" description="Track and split expenses with party members" iconSrc={expenseIcon} color="white" link={`/host-dashboard/event/${eventId}/expense-tracker`} />
            <EventSection title="PARTY CHAT" description="Chat and gossip with party members" iconSrc={expenseIcon} color="white" link={`/host-dashboard/event/${eventId}/party-chat`} />
            <EventSection title="PARTY MENU" description="Set the party menu with guest preferences" iconSrc={chatIcon} color="white" link={`/host-dashboard/event/${eventId}/party-menu`} />
          </div>
        </div>
  
        {/* Feedback Modal */}
        <button onClick={openFeedbackModal} className="fixed bottom-4 right-4 z-50 bg-fontGreen text-white py-2 px-4 rounded-lg shadow-md hover:shadow-xl transition transform hover:scale-105">
          Have Suggestions?
        </button>
  
        {feedbackModalOpen && (
          <FeedbackModal
            feedbackText={feedbackText}
            setFeedbackText={setFeedbackText}
            closeFeedbackModal={closeFeedbackModal}
            handleFeedbackSubmit={handleFeedbackSubmit}
          />
        )}
  
        {/* Edit Banner Modal */}
        {bannerModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full relative">
      <button
        onClick={closeBannerModal}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
      >
        &#x2715;
      </button>
      <h2 className="text-lg font-bold mb-4">Upload a Custom Banner</h2>
      <p className="text-sm text-gray-600 mb-4">Ideal resolution: 1200x400px. Supported formats: PNG, JPG.</p>
      <input type="file" accept="image/png, image/jpeg" onChange={handleBannerChange} />
      
      {previewLoading ? (
        <p className="text-center text-gray-500 mt-4">Generating Preview...</p>
      ) : (
        previewBannerUrl && (
          <img
            src={previewBannerUrl}
            alt="Preview Banner"
            className="w-full h-auto mt-4 rounded-lg shadow-md"
          />
        )
      )}

      <div className="flex justify-between mt-6">
        <button
          onClick={revertToDefaultBanner}
          className="bg-gray-300 text-black py-2 px-4 rounded hover:bg-gray-400 transition"
        >
          Revert to Default
        </button>
        <button
          onClick={saveCustomBanner}
          className="bg-fontGreen text-white py-2 px-4 rounded hover:bg-fontGreen transition"
        >
          Save
        </button>
      </div>
    </div>
  </div>
)}


      </main>
    </div>
  );
  
}

const EventSection = ({ title, description, iconSrc, color, link }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`bg-${color} text-fontGreen p-4 rounded-2xl cursor-pointer transition transform hover:scale-105 border-fontGreen border-4 flex items-center`}
      onClick={() => navigate(link)}
    >
      {/* Icon on the left side */}
      <img src={iconSrc} alt={`${title} icon`} className="w-14 h-14 mr-4" />
      
      {/* Text content on the right side */}
      <div>
        <h3 className="text-2xl font-bold">{title}</h3>
        <p className="text-xl">{description}</p>
      </div>
    </div>
  );
};


const FeedbackModal = ({ feedbackText, setFeedbackText, closeFeedbackModal, handleFeedbackSubmit }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative">
      <button onClick={closeFeedbackModal} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
        &#x2715;
      </button>
      <h2 className="text-lg font-bold mb-4">Bugs? New Ideas? Something missing here? We'd love your feedback</h2>
      <textarea value={feedbackText} onChange={(e) => setFeedbackText(e.target.value)} className="w-full p-3 border rounded mb-4" rows="4" placeholder="Enter feedback here..." />
      <div className="flex justify-end gap-2">
        <button onClick={closeFeedbackModal} className="bg-gray-300 text-black py-2 px-4 rounded hover:bg-gray-400 transition">
          Cancel
        </button>
        <button onClick={handleFeedbackSubmit} className="bg-fontGreen text-white py-2 px-4 rounded hover:bg-fontGreen transition">
          Submit
        </button>
      </div>
    </div>
  </div>
);

export default EventDetail;
