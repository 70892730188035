import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Login from '../pages/Login/Login';
import RoleSelection from '../pages/RoleSelection/RoleSelection';
import HostDashboard from '../pages/HostDashboard/HostDashboard';
import GuestDashboard from '../pages/GuestDashboard/GuestDashboard';
import EventDetails from '../pages/HostEvent/EventDetails';
import RSVPManager from '../pages/HostEvent/RSVPManager';
import MenuManager from '../pages/HostEvent/MenuManager';
import GuestViewSettings from '../pages/HostEvent/GuestViewSettings';
import GuestEventDetails from '../pages/GuestEvents/GuestEventDetails';
import ExpenseTracker from '../pages/HostEvent/ExpenseTracker'; // Import the new ExpenseTracker component
import MoviePicker from '../pages/HostEvent/MoviePicker'; // Import the new Movie Picker component 
import PartyChat from '../pages/HostEvent/PartyChat'; // Import the new Movie Picker component 
import PartyMenu from '../pages/HostEvent/PartyMenu'; // Import the new Movie Picker component 


// Utility function to check if the user is authenticated
const isAuthenticated = () => {
  return localStorage.getItem('user') !== null;
};

// Protected Route component to handle authentication and redirection
const ProtectedRoute = ({ element: Component, ...rest }) => {
  const isLoggedIn = isAuthenticated();
  const location = useLocation();
  
  if (!isLoggedIn) {
    // Store the eventId temporarily in localStorage for redirect after login
    const eventId = location.pathname.split('/guest-event/')[1];
    if (eventId) {
      localStorage.setItem('redirectEventId', eventId);
    }
    return <Navigate to="/" replace />;
  }

  return <Component {...rest} />;
};

function AppRoutes() {
  return (
    <Router>
      <Routes>
        {/* Authentication and Role Selection */}
        <Route path="/" element={<Login />} />
        <Route path="/role-selection" element={<RoleSelection />} />

        {/* Host Views */}
        <Route path="/host-dashboard" element={<HostDashboard />} />
        
        {/* Host Event Detail Views */}
        <Route path="/host-dashboard/event/:eventId" element={<EventDetails />} />
        <Route path="/host-dashboard/event/:eventId/rsvp" element={<RSVPManager />} />
        <Route path="/host-dashboard/event/:eventId/party-chat" element={<PartyChat />} /> {/* New ExpenseTracker route */}

        <Route path="/host-dashboard/event/:eventId/menu" element={<MenuManager />} />
        <Route path="/host-dashboard/event/:eventId/guest-view-settings" element={<GuestViewSettings />} />
        <Route path="/host-dashboard/event/:eventId/expense-tracker" element={<ExpenseTracker />} /> {/* New ExpenseTracker route */}
        <Route path="/host-dashboard/event/:eventId/movie-picker" element={<MoviePicker />} /> {/* New ExpenseTracker route */}
        <Route path="/host-dashboard/event/:eventId/party-menu" element={<PartyMenu />} /> {/* New ExpenseTracker route */}

        {/* Guest Views */}
        <Route path="/guest-dashboard" element={<GuestDashboard />} />
        <Route 
          path="/guest-event/:eventId" 
          element={<ProtectedRoute element={GuestEventDetails} />} 
        />
        {/* Guest Party Chat Route */}
        <Route
          path="/party-chat/:eventId"
          element={<ProtectedRoute element={PartyChat} />}
        />

        {/* Catch-All Route for Not Found Pages */}
        <Route path="*" element={<div>404 Page Not Found</div>} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
