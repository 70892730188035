import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import GuestEventDetails from '../GuestEvents/GuestEventDetails';
import { FiArrowLeft } from 'react-icons/fi';
import logo from '../../assets/images/logos/logo-partysync-official.png';
import logotext from '../../assets/images/logos/logo-partysync-text-v2.png';

function GuestViewSettings() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [guestViewSettings, setGuestViewSettings] = useState({
    showGuestList: true,
    showMenuList: true,
    showPartyChat: true, // Add this field
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const fetchGuestViewSettings = async () => {
      try {
        const eventDoc = await getDoc(doc(db, 'events', eventId));
        if (eventDoc.exists()) {
          const data = eventDoc.data();
          setGuestViewSettings({
            showGuestList: data.guestViewSettings?.showGuestList ?? true,
            showMenuList: data.guestViewSettings?.showMenuList ?? true,
            showPartyChat: data.guestViewSettings?.showPartyChat ?? true, // Include showPartyChat
          });
        }
      } catch (error) {
        console.error('Error fetching guest view settings:', error);
      }
    };

    fetchGuestViewSettings();
  }, [eventId]);

  const handleToggleChange = async (setting) => {
    const updatedSettings = {
      ...guestViewSettings,
      [setting]: !guestViewSettings[setting],
    };
    setGuestViewSettings(updatedSettings);

    try {
      const eventRef = doc(db, 'events', eventId);
      await updateDoc(eventRef, { guestViewSettings: updatedSettings });
      console.log(`Updated ${setting} to`, updatedSettings[setting]);
    } catch (error) {
      console.error('Error updating guest view settings:', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
        <img
          src={logotext}
          alt="PartySync Logo"
          className="h-8 cursor-pointer"
        />
        <button
          className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
          onClick={handleLogout}
        >
          LOG OUT
        </button>
      </header>

      <div className="flex flex-grow bg-light mt-24">
        {/* Sidebar (Settings) */}
        <div
          className={`${
            isSidebarOpen ? 'w-full md:w-1/4' : 'w-0'
          } transition-all duration-300 bg-offwhite p-6 shadow-md`}
        >
          {isSidebarOpen && (
            <>
              <button
                className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-4xl transform hover:scale-105 mb-6"
                onClick={() => navigate(-1)}
              >
                <FiArrowLeft size={32} />
              </button>
              <h3 className="text-xl font-semibold text-primary mb-4">Customize Invite</h3>
              <div className="flex flex-col gap-4">
                {/* Show Guest List Toggle */}
                <div className="flex justify-between items-center">
                  <span>Show Guest List</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={guestViewSettings.showGuestList}
                      onChange={() => handleToggleChange('showGuestList')}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-medgrey peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary rounded-full peer dark:bg-gray-700 peer-checked:bg-primary relative">
                      <span className="absolute left-1 top-1 bg-offwhite w-4 h-4 rounded-full transition-transform duration-300 transform peer-checked:translate-x-5"></span>
                    </div>
                  </label>
                </div>

                {/* Show Menu List Toggle */}
                <div className="flex justify-between items-center">
                  <span>Show Menu List</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={guestViewSettings.showMenuList}
                      onChange={() => handleToggleChange('showMenuList')}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-medgrey peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary rounded-full peer dark:bg-gray-700 peer-checked:bg-primary relative">
                      <span className="absolute left-1 top-1 bg-offwhite w-4 h-4 rounded-full transition-transform duration-300 transform peer-checked:translate-x-5"></span>
                    </div>
                  </label>
                </div>

                {/* Show Party Chat Toggle */}
                <div className="flex justify-between items-center">
                  <span>Show Party Chat</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={guestViewSettings.showPartyChat}
                      onChange={() => handleToggleChange('showPartyChat')}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-medgrey peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary rounded-full peer dark:bg-gray-700 peer-checked:bg-primary relative">
                      <span className="absolute left-1 top-1 bg-offwhite w-4 h-4 rounded-full transition-transform duration-300 transform peer-checked:translate-x-5"></span>
                    </div>
                  </label>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Preview Section with Scrollable Container */}
        <div className="flex-1 bg-offwhite p-6 transition-all duration-300 max-w-8xl mx-auto mt-4 md:mt-0 md:ml-4">
          <span className="text-2xl font-semibold text-primary">Interactive Preview</span>

          <div className="border p-4 rounded-lg shadow-inner overflow-auto max-h-[80vh]">
            <GuestEventDetails
              eventId={eventId}
              guestViewSettings={guestViewSettings}
              previewMode={true} // Use a prop to indicate that it's in preview mode
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuestViewSettings;
