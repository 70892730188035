import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import { collection, getDoc, getDocs, addDoc, updateDoc, doc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { FiChevronDown, FiChevronUp, FiEdit3, FiTrash, FiPlus, FiArrowLeft } from 'react-icons/fi';
import logo from '../../assets/images/logos/logo-partysync-official.png';
import logotext from '../../assets/images/logos/logo-partysync-text-v2.png';

import { logout } from '../../firebase/auth';

function ExpenseTracker() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [expenses, setExpenses] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [newExpense, setNewExpense] = useState({
    title: '',
    amount: '',
    paidBy: '',
    splitType: 'equal',
    customSplit: {}
  });
  const [editExpenseId, setEditExpenseId] = useState(null);
  const [guests, setGuests] = useState([]);
  const [balanceSummary, setBalanceSummary] = useState({
    totalSpend: 0,
    owes: 0,
    owed: 0,
    oweDetails: {},
    owedDetails: {}
  });
  const [isHostOrCoHost, setIsHostOrCoHost] = useState(false);
  const [expanded, setExpanded] = useState(null);
  const [showOweDetails, setShowOweDetails] = useState(false);
  const [showOwedDetails, setShowOwedDetails] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;

    if (!userEmail) {
      alert('User email is missing. Please log in again.');
      navigate('/login');
      return;
    }

    const fetchEventDetails = async () => {
      const eventDocRef = doc(db, 'events', eventId);
      const eventSnapshot = await getDoc(eventDocRef);

      if (eventSnapshot.exists()) {
        const eventData = eventSnapshot.data();
        if (eventData.hostEmail === userEmail) {
          setIsHostOrCoHost(true);
        }
      }

      const rsvpSnapshot = await getDocs(collection(db, 'events', eventId, 'rsvps'));
      const guestsList = [];
      rsvpSnapshot.forEach((doc) => {
        const data = doc.data();
        guestsList.push({ id: doc.id, ...data });
        if (data.email === userEmail && data.isCoHost) {
          setIsHostOrCoHost(true);
        }
      });
      setGuests(guestsList);
    };

    fetchEventDetails();

    const unsubscribeExpenses = onSnapshot(collection(db, 'events', eventId, 'expenses'), snapshot => {
      const expenseList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setExpenses(expenseList);
      calculateBalance(expenseList);
    });

    return () => {
      unsubscribeExpenses();
    };
  }, [eventId, navigate]);

  useEffect(() => {
    if (guests.length > 0 && expenses.length > 0) {
      calculateBalance(expenses);
    }
  }, [guests, expenses]);

  const calculateBalance = (expenseList) => {
    let totalSpend = 0;
    let owes = 0;
    let owed = 0;
    const oweDetails = {};
    const owedDetails = {};

    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;

    expenseList.forEach(expense => {
      totalSpend += expense.amount;

      if (expense.splitType === 'equal') {
        const share = expense.amount / guests.length;
        guests.forEach(guest => {
          if (guest.email === userEmail) {
            if (expense.paidBy === userEmail) {
              owed += share * (guests.length - 1);
              guests.forEach(g => {
                if (g.email !== userEmail) {
                  owedDetails[g.email] = (owedDetails[g.email] || 0) + share;
                }
              });
            } else {
              owes += share;
              oweDetails[expense.paidBy] = (oweDetails[expense.paidBy] || 0) + share;
            }
          }
        });
      } else if (expense.splitType === 'custom') {
        Object.entries(expense.customSplit).forEach(([guestEmail, amount]) => {
          if (guestEmail === userEmail) {
            owes += amount;
            oweDetails[expense.paidBy] = (oweDetails[expense.paidBy] || 0) + amount;
          } else if (expense.paidBy === userEmail) {
            owed += amount;
            owedDetails[guestEmail] = (owedDetails[guestEmail] || 0) + amount;
          }
        });
      }
    });

    setBalanceSummary({
      totalSpend,
      owes,
      owed,
      oweDetails,
      owedDetails
    });
  };

  const toggleFormVisibility = () => setIsFormVisible(!isFormVisible);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewExpense(prevExpense => ({
      ...prevExpense,
      [name]: name === 'amount' ? parseFloat(value) || '' : value
    }));
  };

  const handleCustomSplitChange = (guestEmail, amount) => {
    setNewExpense(prevExpense => ({
      ...prevExpense,
      customSplit: {
        ...prevExpense.customSplit,
        [guestEmail]: parseFloat(amount) || 0
      }
    }));
  };

  const handleCreateOrUpdateExpense = async (e) => {
    e.preventDefault();
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;

    if (!userEmail) {
      alert('User email is missing. Please log in again.');
      navigate('/login');
      return;
    }

    try {
      const expenseCollection = collection(db, 'events', eventId, 'expenses');
      const updatedExpense = {
        ...newExpense,
        addedBy: userEmail,
      };

      if (editExpenseId) {
        const expenseDocRef = doc(db, 'events', eventId, 'expenses', editExpenseId);
        await updateDoc(expenseDocRef, updatedExpense);
      } else {
        await addDoc(expenseCollection, updatedExpense);
      }
      closeForm();
    } catch (error) {
      console.error('Error adding/updating expense: ', error);
    }
  };

  const closeForm = () => {
    setIsFormVisible(false);
    setEditExpenseId(null);
    setNewExpense({ title: '', amount: '', paidBy: '', splitType: 'equal', customSplit: {} });
  };

  const handleEditExpense = (expense) => {
    setEditExpenseId(expense.id);
    setNewExpense({
      title: expense.title,
      amount: expense.amount,
      paidBy: expense.paidBy,
      splitType: expense.splitType,
      customSplit: expense.customSplit || {}
    });
    setIsFormVisible(true);
  };

  const handleDeleteExpense = async (expenseId) => {
    try {
      const expenseDocRef = doc(db, 'events', eventId, 'expenses', expenseId);
      await deleteDoc(expenseDocRef);
    } catch (error) {
      console.error('Error deleting expense: ', error);
    }
  };

  const toggleAccordion = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleLogout = () => {
    navigate('/'); // Redirect to home or logout route
  };

  return (
    <div className="flex flex-col min-h-screen">
    {/* Standardized Header */}
    <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
      <img
        src={logotext}
        alt="PartySync Logo"
        className="h-8 cursor-pointer"
     //   onClick={() => navigate('/')}
      />
      <button
        className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
        onClick={handleLogout}
      >
      LOG OUT
      </button>
    </header>

    <main className="p-6 min-h-screen bg-gradient-to-b from-offwhite to-offwhite mt-20">
      {/* Back Button Below Header */}
      <div className="flex justify-start items-center mb-4">
        <button
          className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
          onClick={() => navigate(-1)}
        >
          <FiArrowLeft size={32} />
        </button>
      </div>

        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-primary mb-8">Expense Tracker</h2>

          {/* Enhanced Expense Summary with Accordion-style breakdown */}
          <div className="bg-blue-100 p-6 rounded-lg mb-6 shadow-md">
            <h3 className="text-xl font-semibold mb-4">Your Expense Summary</h3>
            <p className="text-lg mb-2"><strong>Your Total Spend:</strong> ${balanceSummary.totalSpend.toFixed(2)}</p>

            {/* You Owe Section */}
            <p className="text-lg flex items-center cursor-pointer mb-2" onClick={() => setShowOweDetails(!showOweDetails)}>
              <strong>You Owe:</strong> ${balanceSummary.owes.toFixed(2)}
              {showOweDetails ? <FiChevronUp className="ml-2" /> : <FiChevronDown className="ml-2" />}
            </p>
            {showOweDetails && (
              <div className="pl-4 mb-4 text-sm text-gray-700">
                {Object.entries(balanceSummary.oweDetails).map(([user, amount]) => (
                  <p key={user}>→ To {user}: ${amount.toFixed(2)}</p>
                ))}
              </div>
            )}

            {/* You are Owed Section */}
            <p className="text-lg flex items-center cursor-pointer mb-2" onClick={() => setShowOwedDetails(!showOwedDetails)}>
              <strong>You are Owed:</strong> ${balanceSummary.owed.toFixed(2)}
              {showOwedDetails ? <FiChevronUp className="ml-2" /> : <FiChevronDown className="ml-2" />}
            </p>
            {showOwedDetails && (
              <div className="pl-4 mb-4 text-sm text-gray-700">
                {Object.entries(balanceSummary.owedDetails).map(([user, amount]) => (
                  <p key={user}>→ Owed by {user}: ${amount.toFixed(2)}</p>
                ))}
              </div>
            )}
          </div>

          {/* Add Expense Button */}
          <button
            className="w-full bg-primary text-white py-3 px-6 rounded-lg shadow-lg hover:bg-secondary transition flex items-center justify-center gap-2 font-semibold text-lg mb-4"
            onClick={toggleFormVisibility}
          >
            <FiPlus size={24} />
            Add Expense
          </button>

          {/* Form for Adding New Expense */}
          {isFormVisible && (
            <div className="bg-white p-4 rounded-lg shadow-md my-4">
              <form onSubmit={handleCreateOrUpdateExpense}>
                <input
                  type="text"
                  name="title"
                  placeholder="Expense Title"
                  value={newExpense.title}
                  onChange={handleChange}
                  className="p-3 border border-gray-300 rounded-lg w-full mb-3"
                  required
                />
                <input
                  type="number"
                  name="amount"
                  placeholder="Amount ($)"
                  value={newExpense.amount}
                  onChange={handleChange}
                  className="p-3 border border-gray-300 rounded-lg w-full mb-3"
                  required
                />
                <select
                  name="paidBy"
                  value={newExpense.paidBy}
                  onChange={handleChange}
                  className="p-3 border border-gray-300 rounded-lg w-full mb-3"
                  required
                >
                  <option value="" disabled>Select Who Paid</option>
                  {guests.map(guest => (
                    <option key={guest.id} value={guest.email}>
                      {guest.name}
                    </option>
                  ))}
                </select>
                <select
                  name="splitType"
                  value={newExpense.splitType}
                  onChange={handleChange}
                  className="p-3 border border-gray-300 rounded-lg w-full mb-3"
                >
                  <option value="equal">Split Equally</option>
                  <option value="custom">Custom Split</option>
                </select>
                {newExpense.splitType === 'custom' && (
                  <div className="custom-split">
                    <h4 className="font-semibold mb-2">Custom Split Amounts</h4>
                    {guests.map(guest => (
                      <div key={guest.id} className="flex items-center mb-2">
                        <label className="flex-1">{guest.name}</label>
                        <input
                          type="number"
                          value={newExpense.customSplit[guest.email] || ''}
                          onChange={(e) => handleCustomSplitChange(guest.email, e.target.value)}
                          placeholder="Amount"
                          className="p-2 border border-gray-300 rounded-lg"
                        />
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type="submit"
                  className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-accent transition w-full"
                >
                  {editExpenseId ? 'Update Expense' : 'Add Expense'}
                </button>
              </form>
            </div>
          )}

          {/* Expense List */}
          {expenses.map((expense, index) => (
            <div key={expense.id} className="bg-purple-100 rounded-lg mb-3 shadow-md">
              <div
                className="p-4 flex justify-between items-center cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <div>
                  <h3 className="text-lg font-bold">{expense.title}</h3>
                  <p className="text-gray-700">Amount: ${expense.amount}</p>
                </div>
                <div>
                  {expanded === index ? <FiChevronUp /> : <FiChevronDown />}
                </div>
              </div>

              {expanded === index && (
                <div className="bg-purple-200 p-4">
                  <p><strong>Paid By:</strong> {expense.paidBy}</p>
                  <p><strong>Split Type:</strong> {expense.splitType === 'equal' ? 'Equally' : 'Custom'}</p>
                  {expense.splitType === 'custom' && (
                    <div className="mt-3">
                      <h4 className="font-semibold">Custom Split Details:</h4>
                      {Object.entries(expense.customSplit).map(([guest, amount]) => (
                        <p key={guest}>{guest}: ${amount}</p>
                      ))}
                    </div>
                  )}
                  <div className="flex justify-end mt-4">
                    <button
                      className="text-yellow-500 hover:text-yellow-700 transition mx-2"
                      onClick={() => handleEditExpense(expense)}
                    >
                      <FiEdit3 size={20} title="Edit Expense" />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-700 transition"
                      onClick={() => handleDeleteExpense(expense.id)}
                    >
                      <FiTrash size={20} title="Delete Expense" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}

export default ExpenseTracker;
