import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase/firebaseConfig';
import { collection, addDoc, onSnapshot, serverTimestamp, doc, getDocs, query, where, getDoc } from 'firebase/firestore';
import { FiLogOut, FiArrowLeft } from 'react-icons/fi';
import logo from '../../assets/images/logos/logo-partysync-official.png';
import logotext from '../../assets/images/logos/logo-partysync-text-v2.png';

function PartyChat() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [comment, setComment] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [comments, setComments] = useState([]);
  const [userRsvpId, setUserRsvpId] = useState(null);

  useEffect(() => {
    const fetchUserRsvpId = async () => {
      const storedUser = localStorage.getItem('user');
      const userEmail = storedUser ? JSON.parse(storedUser).email : null;

      if (!userEmail) {
        alert('User email is missing. Please log in again.');
        navigate('/login');
        return;
      }

      // Fetch RSVP data for the current user based on email
      const rsvpsCollection = collection(db, 'events', eventId, 'rsvps');
      const q = query(rsvpsCollection, where('email', '==', userEmail));
      const rsvpSnapshot = await getDocs(q);

      if (!rsvpSnapshot.empty) {
        setUserRsvpId(rsvpSnapshot.docs[0].id); // Set the RSVP ID
      } else {
        console.log("No RSVP found for the current user.");
      }
    };

    fetchUserRsvpId();

    // Real-time listener for comments
    const commentsCollection = collection(db, 'events', eventId, 'comments');
    const unsubscribe = onSnapshot(commentsCollection, (snapshot) => {
      const fetchedComments = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setComments(fetchedComments.sort((a, b) => b.timestamp - a.timestamp)); // Sort by newest first
    });

    return () => unsubscribe();
  }, [eventId, navigate]);

  const handlePostComment = async () => {
    if (comment.trim() === '') {
      console.log("Comment is empty");
      return;
    }

    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;

    if (!userEmail || !userRsvpId) {
      console.log("User email or RSVP ID is missing");
      return;
    }

    try {
      await addDoc(collection(db, 'events', eventId, 'comments'), {
        text: comment,
        rsvpId: userRsvpId,
        timestamp: serverTimestamp(),
        isAnonymous
      });
      setComment(''); // Clear input after posting
      console.log("Comment posted successfully");
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Standardized Header */}
      <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
        <img
          src={logotext}
          alt="PartySync Logo"
          className="h-8 cursor-pointer"
      //    onClick={() => navigate('/')}
        />
        <button
          className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
          onClick={() => navigate('/')}
        >
        LOG OUT
        </button>
      </header>

      <main className="p-6 min-h-screen bg-gradient-to-b from-offwhite to-offwhite mt-20">
        {/* Back Button Below Header */}
        <div className="flex justify-start items-center mb-4">
          <button
            className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
            onClick={() => navigate(-1)}
          >
            <FiArrowLeft size={32} />
          </button>
        </div>

        <h2 className="text-2xl text-fontGreen uppercase font-bold mb-4">Party Chat</h2>
{/* Post as Toggle */}
<div className="flex items-center mb-4">
  <span className="mr-2 text-xl text-fontGreen font-bold">Post as:</span>
  <div className="flex items-center space-x-2">
    <button
      onClick={() => setIsAnonymous(false)}
      className={`py-1 px-4 rounded-2xl ${!isAnonymous ? 'bg-canvared font-bold text-white' : 'bg-gray-200 text-fontGreen'} font-semibold transition`}
    >
      YOU
    </button>
    <button
      onClick={() => setIsAnonymous(true)}
      className={`py-1 px-4 rounded-2xl ${isAnonymous ? 'bg-canvared font-bold text-white' : 'bg-gray-200 text-fontGreen'} font-semibold transition`}
    >
      ANONYMOUS
    </button>
  </div>
</div>
        {/* Comment Input Section */}
        <div className="mb-4">
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Type your comment here..."
            className="w-full p-3 border-2 border-black rounded-xl mb-2"
          />
     

          <button
            onClick={handlePostComment}
            className="bg-fontGreen uppercase font-bold text-md text-white py-2 px-4 rounded-2xl hover:bg-green-700 transition"
          >
            submit
          </button>
        </div>

        {/* Display Comments */}
        <div className="space-y-4 relative">
          {comments.map((comment, index) => (
            <Comment
              key={comment.id}
              text={comment.text}
              rsvpId={comment.rsvpId}
              timestamp={comment.timestamp}
              isAnonymous={comment.isAnonymous}
              eventId={eventId}
              isUserComment={!comment.isAnonymous && comment.rsvpId === userRsvpId}
              isFirst={index === 0}
            />
          ))}
          {/* Vertical Line for Threaded Effect */}
          <div className="absolute left-5 top-0 bottom-0 w-0.5 bg-black"></div>
        </div>
      </main>
    </div>
  );
}

// Comment Component to display individual comments
const Comment = ({ text, rsvpId, timestamp, isAnonymous, eventId, isUserComment, isFirst }) => {
  const [username, setUsername] = useState('');

  useEffect(() => {
    if (!isAnonymous && rsvpId) {
      const fetchUsername = async () => {
        const rsvpDoc = await getDoc(doc(db, 'events', eventId, 'rsvps', rsvpId));
        if (rsvpDoc.exists()) {
          setUsername(rsvpDoc.data().name);
        } else {
          console.log("RSVP document not found for rsvpId:", rsvpId);
        }
      };
      fetchUsername();
    }
  }, [isAnonymous, rsvpId, eventId]);

  // Format timestamp
  const formattedTimestamp = timestamp
    ? new Date(timestamp.seconds * 1000).toLocaleString()
    : 'Loading...';

  return (
    <div className={`p-4 rounded-xl border-black border ${isUserComment ? 'bg-white' : 'bg-white'} relative ml-10`}>
      {!isFirst && <div className="absolute left-[-10px] top-0 h-6 w-0.5 bg-black"></div>}
      <div className="mb-1 font-semibold uppercase text-fontGreen">
        {isAnonymous ? "Anonymous" : username || "Unknown"}
      </div>
      <p className="text-fontGreen">{text}</p>
      <div className="text-fontGreen text-sm mt-1">{formattedTimestamp}</div>
    </div>
  );
};

export default PartyChat;
