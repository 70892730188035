import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import {
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import { FiArrowLeft } from 'react-icons/fi';
import logotext from '../../assets/images/logos/logo-partysync-text-v2.png';

const PartyMenu = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [categories, setCategories] = useState([]);
  const [menuInstruction, setMenuInstruction] = useState(''); // State for menu instructions

  // Fetch existing menuConfig and menuInstruction on load
  useEffect(() => {
    const fetchMenuConfig = async () => {
      try {
        const eventDoc = await getDoc(doc(db, 'events', eventId));
        if (eventDoc.exists()) {
          const eventData = eventDoc.data();
          const menuConfig = eventData.menuConfig || { enabled: false, categories: [] };
          setEnabled(menuConfig.enabled);
          setCategories(menuConfig.categories || []);
          setMenuInstruction(menuConfig.menuInstruction || ''); // Ensure menuInstruction is set
        } else {
          console.error('Event not found');
        }
      } catch (error) {
        console.error('Error fetching menu config:', error);
      }
    };

    fetchMenuConfig();
  }, [eventId]);

  // Save the menuConfig and menuInstruction to Firestore
  const saveMenuConfig = async () => {
    try {
      const eventRef = doc(db, 'events', eventId);
      await setDoc(
        eventRef,
        {
          menuConfig: {
            enabled,
            categories,
            menuInstruction, // Save menu instruction inside menuConfig
          },
        },
        { merge: true }
      );
      alert('Menu configuration saved successfully!');
    } catch (error) {
      console.error('Error saving menu configuration:', error);
      alert('Failed to save configuration.');
    }
  };

  // Add a new category
  const addCategory = () => {
    setCategories([...categories, { name: '', limit: 1, options: [] }]);
  };

  // Update category details
  const updateCategory = (index, field, value) => {
    const updatedCategories = [...categories];
    updatedCategories[index][field] = value;
    setCategories(updatedCategories);
  };

  // Add an option to a category
  const addOption = (index) => {
    const updatedCategories = [...categories];
    updatedCategories[index].options.push('');
    setCategories(updatedCategories);
  };

  // Update an option within a category
  const updateOption = (catIndex, optIndex, value) => {
    const updatedCategories = [...categories];
    updatedCategories[catIndex].options[optIndex] = value;
    setCategories(updatedCategories);
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Standardized Header */}
      <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
        <img
          src={logotext}
          alt="PartySync Logo"
          className="h-8 cursor-pointer"
          onClick={() => navigate('/')}
        />
        <button
          className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
          onClick={() => navigate('/')}
        >
          LOG OUT
        </button>
      </header>

      <main className="p-6 min-h-screen bg-gradient-to-b from-offwhite to-offwhite mt-20">
        {/* Back Button */}
        <div className="flex justify-start items-center mb-4">
          <button
            className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
            onClick={() => navigate(-1)}
          >
            <FiArrowLeft size={32} />
          </button>
        </div>

        <h2 className="text-2xl text-fontGreen uppercase font-bold mb-4">Party Menu Configuration</h2>

        {/* Toggle Party Menu Feature */}
        <div className="flex items-center mb-4">
          <label className="text-xl text-fontGreen font-bold mr-4">
            Enable Party Menu
          </label>
          <input
            type="checkbox"
            checked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
            className="w-6 h-6"
          />
        </div>

        {/* Menu Instructions */}
        {enabled && (
          <>
            <div className="mb-6">
              <h3 className="text-lg text-fontGreen font-bold mb-2">Menu Instruction</h3>
              <textarea
                value={menuInstruction}
                onChange={(e) => setMenuInstruction(e.target.value)}
                placeholder="Enter special instructions for the menu (e.g., 'Each guest can choose 2 proteins')"
                className="w-full p-2 border-2 border-black rounded-lg"
                rows={3}
              />
            </div>

            {/* Menu Configuration Form */}
            <button
              onClick={addCategory}
              className="bg-canvared text-white uppercase font-bold text-md py-2 px-4 rounded-2xl hover:bg-red-700 transition mb-4"
            >
              Add Category
            </button>

            {categories.map((category, catIndex) => (
              <div
                key={catIndex}
                className="border-2 border-black rounded-xl p-4 mb-4 bg-white"
              >
                <input
                  type="text"
                  value={category.name}
                  placeholder="Category Name"
                  onChange={(e) =>
                    updateCategory(catIndex, 'name', e.target.value)
                  }
                  className="w-full mb-2 p-2 border-2 border-black rounded-lg"
                />
                <input
                  type="number"
                  value={category.limit}
                  min="1"
                  placeholder="Max Selections"
                  onChange={(e) =>
                    updateCategory(catIndex, 'limit', parseInt(e.target.value))
                  }
                  className="w-full mb-2 p-2 border-2 border-black rounded-lg"
                />
                <button
                  onClick={() => addOption(catIndex)}
                  className="bg-fontGreen text-white uppercase font-bold text-sm py-1 px-3 rounded-lg hover:bg-green-700 transition mb-2"
                >
                  Add Option
                </button>
                {category.options.map((option, optIndex) => (
                  <input
                    key={optIndex}
                    type="text"
                    value={option}
                    placeholder="Option Name"
                    onChange={(e) =>
                      updateOption(catIndex, optIndex, e.target.value)
                    }
                    className="w-full mb-2 p-2 border-2 border-black rounded-lg"
                  />
                ))}
              </div>
            ))}

            <button
              onClick={saveMenuConfig}
              className="bg-fontGreen uppercase font-bold text-md text-white py-2 px-4 rounded-2xl hover:bg-green-700 transition"
            >
              Save Configuration
            </button>
          </>
        )}
      </main>
    </div>
  );
};

export default PartyMenu;
