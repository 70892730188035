import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import { FiLogOut } from 'react-icons/fi';
import logo from '../../assets/images/logos/logo-partysync-official.png';
import logotext from '../../assets/images/logos/logo-partysync-text-v2.png';

import pageTitleImage from '../../assets/images/roleSelection/pageTitle.png';
import { logout } from '../../firebase/auth';

function RoleSelection() {
  const navigate = useNavigate();
  const [currentStatementIndex, setCurrentStatementIndex] = useState(0);
  const [firstName, setFirstName] = useState("");

  const statements = [
    "Throw a Birthday Bash",
    "Host a Bridal Shower",
    "Bachelorette Party",
    "Host a Halloween Party",
    "Organize a Dinner Party",
    "Plan Thanksgiving",
    "Plan a Friendsgiving",
    "Plan a Surprise Party",
    "Family Reunion",
    "Plan a Graduation Party"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStatementIndex((prevIndex) => (prevIndex + 1) % statements.length);
    }, 1800);

    return () => clearInterval(interval);
  }, [statements.length]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser && storedUser.uid) {
      const userId = storedUser.uid;
      fetchUserData(userId);
    }
  }, []);

  const fetchUserData = async (userId) => {
    try {
      const userRef = doc(db, 'Users', userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.firstName) {
          setFirstName(userData.firstName);
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
        <img
          src={logotext}
          alt="PartySync Logo"
          className="h-8 cursor-pointer"
        />
        <button
          className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
          onClick={handleLogout}
        >
          LOG OUT
        </button>
      </header>

      <main className="flex flex-col items-center bg-offwhite justify-center flex-1 mt-20 px-4">
        {firstName && (
          <div className="text-center mb-2 text-3xl font-bold text-fontGreen">
            Welcome, {firstName}!
          </div>
        )}
        
        {/* Page Title Image */}
        <img
          src={pageTitleImage}
          alt="Are you ready to party?"
          className="mb-4 w-full max-w-2xl" // Makes the image larger
          style={{ maxWidth: '600px' }} // Custom max-width in pixels (optional)
        />

        {/* Role Selection Buttons */}
        <div className="flex flex-col gap-6 w-full md:w-auto">
          <button
            onClick={() => navigate('/host-dashboard')}
            className="bg-fontGreen text-white py-3 px-6 font-bold text-lg rounded-full hover:bg-fontGreen transition transform hover:scale-105 w-full"
          >
            ENTER AS HOST
          </button>
          <button
            onClick={() => navigate('/guest-dashboard')}
            className="bg-canvared text-white py-3 px-6 text-lg font-bold rounded-full hover:bg-canvared transition transform hover:scale-105 w-full"
          >
            ENTER AS GUEST
          </button>

        </div>
      </main>
    </div>
  );
}

export default RoleSelection;
